<template>
    <client-page>

        <sub-visual
        sh="소식"
        sc="에프원시큐리티의 최신소식입니다"
        tabActive="NEWS"
        icon="/images/sub/visual/sv4-icon.svg"
        bg="/images/sub/visual/sv4.png">
        </sub-visual>

        <section class="sub-section sub-section--last">
            <router-view name="board" code="news" skin="news" hide-write-button />
        </section>
	
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>
